import {Controller, useFieldArray} from "react-hook-form";
import {Button, Col, FormGroup, Label, ListGroup, ListGroupItem, Row} from "reactstrap";
import {TextControllerRHF} from "../../generic/fields";
import {t} from "i18next";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {FaPlus, FaTrash} from "react-icons/fa";

const InstallmentInputs = ({ control, errors, entity, register }) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'installment'
    });

    return <>
        <Label>
            {t('directories.legalEntities.installment.label')}
        </Label>
        <Row>
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF
                        inputName='installment_api_key'
                        control={control}
                        errors={errors}
                        defaultValue={entity.installment_api_key || ''}
                        placeholder=''
                        label={t('directories.legalEntities.installment.showcaseId')}
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF
                        inputName='installment_shop_id'
                        control={control}
                        errors={errors}
                        defaultValue={entity.installment_shop_id || ''}
                        placeholder=''
                        label={t('directories.legalEntities.installment.shopId')}
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF
                        inputName='installment_pass_id'
                        control={control}
                        errors={errors}
                        defaultValue={entity.installment_pass_id || ''}
                        placeholder=''
                        label={t('directories.legalEntities.installment.passId')}
                    />
                </FormGroup>
            </Col>
        </Row>
        <Label>
            {t('directories.legalEntities.installment.types')}
        </Label>
        <ListGroup>
            {fields.map((item, index) => {
                return <ListGroupItem key={index}>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <TextControllerRHF
                                    inputName={`installment.${index}.label`}
                                    control={control}
                                    errors={errors}
                                    defaultValue={item.label || ''}
                                    label={t('common.title')}
                                    innerRef={register()}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <TextControllerRHF
                                    inputName={`installment.${index}.promocode`}
                                    control={control}
                                    errors={errors}
                                    defaultValue={item.promocode || ''}
                                    placeholder='installment_0_0_4_4,5'
                                    label={t('directories.legalEntities.installment.promocode')}
                                    innerRef={register()}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    name={`installment.${index}.markup`}
                                    defaultValue={item.markup || ''}
                                    rules={{
                                        min: {value: 0, message: t('inputs.validation.minNum', {count: 0})},
                                        valueAsNumber: true,
                                        pattern: {value: /^(\d*)\.?\d{0,2}$/}
                                    }}
                                    render={(
                                        {value, onBlur, name, ref, onChange},
                                        {invalid}
                                    ) =>
                                        <FieldWithValidation
                                            invalid={invalid}
                                            errors={errors}
                                            label={t('directories.legalEntities.installment.markup')}
                                            name={name}
                                            onBlur={onBlur}
                                            value={value}
                                            onChange={event => {
                                                const decimalNumberRegex = /^(\d*)\.?\d{0,2}$/;
                                                if (decimalNumberRegex.test(event.target.value)) {
                                                    onChange(event.target.value);
                                                }
                                            }}
                                            innerRef={register()}
                                        />
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Controller
                                    name={`installment.${index}.duration`}
                                    control={control}
                                    defaultValue={item.duration || ''}
                                    rules={{min: {value: 0, message: t('inputs.validation.minNum', {count: 0})}, valueAsNumber: true}}
                                    render={(
                                        {value, onBlur, name, ref, onChange},
                                        {invalid}
                                    ) =>
                                        <FieldWithValidation
                                            invalid={invalid}
                                            errors={errors}
                                            label={t('directories.legalEntities.installment.duration')}
                                            name={name}
                                            onBlur={onBlur}
                                            value={value}
                                            onChange={event => {
                                                const numberRegex = /^\d*$/;
                                                if (numberRegex.test(event.target.value)) {
                                                    onChange(event.target.value);
                                                }
                                            }}
                                            innerRef={register()}
                                        />
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className='text-right'>
                        <Button
                            color="danger"
                            onClick={() => remove(index)}
                        >
                            <FaTrash />
                        </Button>
                    </div>
                </ListGroupItem>
            })}
        </ListGroup>
        <div className='text-center mt-4'>
            <Button
                onClick={() => append({label: "", promocode: "", markup: 0, duration: 0})}
                color="success"
            >
                <FaPlus />
            </Button>
        </div>
    </>
}

export default InstallmentInputs;